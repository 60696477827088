var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('router-link',{staticClass:"\n    uk-link-reset\n    uk-card\n    uk-card-default\n    uk-padding-small\n    uk-box-shadow-medium\n    uk-box-shadow-hover-small\n    uk-border-rounded\n  ",attrs:{"test":"product-card","to":'/store/' + _vm.product.id}},[_c('div',{staticClass:"uk-card-media-top uk-height-medium"},[_c('div',{staticClass:"uk-inline",staticStyle:{"height":"100%","width":"100%"}},[_c('div',{staticClass:"uk-text-center",staticStyle:{"height":"100%","width":"100%"}},[_c('span',{staticStyle:{"display":"inline-block","height":"100%","vertical-align":"middle"}}),_c('img',{staticClass:"uk-border-rounded",staticStyle:{"max-height":"100%","max-width":"100%","vertical-align":"middle"},attrs:{"src":_vm.product.main_image
              ? _vm.product.main_image.url
              : _vm.api + '/storefronts/' + _vm.storefrontId + '/logo',"alt":""}})]),(_vm.product.feature_weight != 0)?_c('div',{staticClass:"\n          uk-badge uk-background-primary\n          text-white\n          uk-margin-small-bottom\n          uk-padding-small\n          uk-position-top-left\n          uk-margin-small-left\n          uk-margin-small-top\n        "},[_c('span',{attrs:{"uk-icon":"heart"}}),_c('span',{staticClass:"uk-margin-small-left"},[_vm._v("Destacado")])]):_vm._e(),(_vm.showQuickview && _vm.storefrontStorefront.type === 'b2b')?_c('div',{staticClass:"\n          uk-position-bottom-right\n          uk-margin-small-right\n          uk-margin-small-bottom\n        "},[_c('button',{staticClass:"uk-button uk-button-secondary uk-border-rounded",attrs:{"href":"#quickview-modal","uk-toggle":""},on:{"click":function($event){return _vm.setActive(_vm.product)}}},[_c('div',{attrs:{"uk-icon":"icon: expand; ratio: 0.7;"}})])]):_vm._e()])]),_c('div',{staticClass:"uk-margin-small-top uk-text-bold uk-text-truncate uk-text-center",staticStyle:{"height":"20%"},attrs:{"title":_vm.storefrontStorefront.type === 'redeem' &&
      _vm.product.storefront_redeemables.length &&
      _vm.product.storefront_redeemables[0].display_name
        ? _vm.product.storefront_redeemables[0].display_name
        : _vm.product.display_name +
          ' - ' +
          (['administrator'].includes(_vm.authUser.type.slug)
            ? _vm.product.name + ' - '
            : '') +
          _vm.product.display_code}},[(
        _vm.storefrontStorefront.type === 'redeem' &&
        _vm.product.storefront_redeemables.length &&
        _vm.product.storefront_redeemables[0].display_name
      )?_c('div',[_c('div',[_vm._v(_vm._s(_vm.product.storefront_redeemables[0].display_name))]),_c('div',{staticClass:"uk-flex uk-flex-middle uk-flex-right",attrs:{"test":"product-card-price"}},[(_vm.pointsIcon)?_c('div',[_c('img',{attrs:{"src":_vm.pointsIcon,"alt":"","width":"30px"}})]):_vm._e(),_c('div',{staticClass:"uk-margin-small-left"},[_vm._v(" "+_vm._s(_vm.formatThousands(_vm.product.storefront_redeemables[0].current_price))+" "+_vm._s(_vm.pointsName)+" ")])])]):_c('div',{staticClass:"uk-flex uk-flex-center"},[_c('div',[_vm._v(_vm._s(_vm.product.display_name))]),(['administrator'].includes(_vm.authUser.type.slug))?_c('div',[_vm._v(" - "+_vm._s(_vm.product.name)+" ")]):_vm._e(),_c('div',{staticClass:"uk-margin-small-left uk-text-muted"},[_vm._v(" "+_vm._s(_vm.product.display_code)+" ")])])]),(_vm.product.startingAtQuantity)?_c('div',[_c('div',[_vm._v(" Desde "+_vm._s(_vm.product.startingAtQuantity)+" unidades ")]),_c('div',[_vm._v(" por "+_vm._s(_vm.toCurrency(_vm.product.startingAtPrice))+" ℆ ")])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }