<template>
  <router-link
    test="product-card"
    class="
      uk-link-reset
      uk-card
      uk-card-default
      uk-padding-small
      uk-box-shadow-medium
      uk-box-shadow-hover-small
      uk-border-rounded
    "
    :to="'/store/' + product.id"
  >
    <div class="uk-card-media-top uk-height-medium">
      <div class="uk-inline" style="height: 100%; width: 100%">
        <div style="height: 100%; width: 100%" class="uk-text-center">
          <span
            style="display: inline-block; height: 100%; vertical-align: middle"
          ></span>
          <img
            style="max-height: 100%; max-width: 100%; vertical-align: middle"
            class="uk-border-rounded"
            :src="
              product.main_image
                ? product.main_image.url
                : api + '/storefronts/' + storefrontId + '/logo'
            "
            alt
          />
        </div>

        <div
          v-if="product.feature_weight != 0"
          class="
            uk-badge uk-background-primary
            text-white
            uk-margin-small-bottom
            uk-padding-small
            uk-position-top-left
            uk-margin-small-left
            uk-margin-small-top
          "
        >
          <span uk-icon="heart"></span>
          <span class="uk-margin-small-left">Destacado</span>
        </div>

        <div
          v-if="showQuickview && storefrontStorefront.type === 'b2b'"
          class="
            uk-position-bottom-right
            uk-margin-small-right
            uk-margin-small-bottom
          "
        >
          <button
            @click="setActive(product)"
            href="#quickview-modal"
            uk-toggle
            class="uk-button uk-button-secondary uk-border-rounded"
          >
            <div uk-icon="icon: expand; ratio: 0.7;"></div>
          </button>
        </div>
      </div>
    </div>
    <div
      class="uk-margin-small-top uk-text-bold uk-text-truncate uk-text-center"
      style="height: 20%"
      :title="
        storefrontStorefront.type === 'redeem' &&
        product.storefront_redeemables.length &&
        product.storefront_redeemables[0].display_name
          ? product.storefront_redeemables[0].display_name
          : product.display_name +
            ' - ' +
            (['administrator'].includes(authUser.type.slug)
              ? product.name + ' - '
              : '') +
            product.display_code
      "
    >
      <div
        v-if="
          storefrontStorefront.type === 'redeem' &&
          product.storefront_redeemables.length &&
          product.storefront_redeemables[0].display_name
        "
      >
        <div>{{ product.storefront_redeemables[0].display_name }}</div>

        <div
          class="uk-flex uk-flex-middle uk-flex-right"
          test="product-card-price"
        >
          <div v-if="pointsIcon">
            <img :src="pointsIcon" alt="" width="30px" />
          </div>
          <div class="uk-margin-small-left">
            {{
              formatThousands(product.storefront_redeemables[0].current_price)
            }}
            {{ pointsName }}
          </div>
        </div>
      </div>
      <div v-else class="uk-flex uk-flex-center">
        <div>{{ product.display_name }}</div>
        <div v-if="['administrator'].includes(authUser.type.slug)">
          - {{ product.name }}
        </div>
        <div class="uk-margin-small-left uk-text-muted">
          {{ product.display_code }}
        </div>
      </div>
    </div>
    <div v-if="product.startingAtQuantity">
      <div>
        Desde
        {{ product.startingAtQuantity }}
        unidades
      </div>
      <div>
        por
        {{ toCurrency(product.startingAtPrice) }}
        &#8454;
      </div>
    </div>
  </router-link>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ProductCard",

  props: ["product", "setActive", "showQuickview"],

  computed: {
    ...mapGetters([
      "storefrontStorefront",
      "authUser",
      "pointsName",
      "pointsIcon",
    ]),
  },
};
</script>